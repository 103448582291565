import React from "react";

const SectionTitle = ({heading, subHeading, className = ''}) => (
  <div className={`${className} section-title container has-text-centered`}>
    <div className="sub-heading">{subHeading || ""}</div>
    <h1>{heading || ""}</h1>
  </div>
);

export default SectionTitle;
