import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import ResourceRoll from "../components/ResourceRoll";
import SectionTitle from "../components/SectionTitle";

export const ResourcesPageTemplate = ({ subtitle, title, description }) => {
  return (
    <div className="resources-page container section">
      <SectionTitle subHeading={subtitle} heading={title} />
      <p className="page-description">{description}</p>
      <div className="seperator" />
      <ResourceRoll />
    </div>
  );
};

const ResourcesPage = ({ data }) => {
  const {
    config: { frontmatter },
  } = data;

  return (
    <Layout title="Resources">
      <ResourcesPageTemplate
        subtitle={frontmatter.subtitle}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  );
};

export default ResourcesPage;

export const resourcesPageQuery = graphql`
  query ResourcesPage {
    config: markdownRemark(
      frontmatter: { templateKey: { eq: "resources-page" } }
    ) {
      frontmatter {
        subtitle
        title
        description
      }
    }
  }
`;
