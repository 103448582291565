import { StaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { getSlugToken, isNotDraft, isPublished } from "../utils/content-utils";
import LoadMoreButton from "./LoadMoreButton";

export const ResourceRollTemplate = ({
  resourceItems = [],
  resourceCategories = [],
}) => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 15;
  useEffect(() => {
    setPage(1);
  }, [categoryIndex]);

  const selectedCategory = resourceCategories[categoryIndex];

  const allItems =
    resourceItems
      .filter(isPublished)
      .filter(
        (item) => item.resource_items_category === selectedCategory.slug
      ) || [];

  allItems.sort((a, b) => {
    if (a.label === b.label) {
      return a.title < b.title ? -1 : 1;
    }
    return a.label < b.label ? 1 : -1;
  });

  const visibleItems = allItems.slice(0, pageSize * page);
  const isLoadMoreEnabled = pageSize * page <= allItems.length;

  // console.debug(resourceItems);
  // console.debug(resourceCategories);
  // console.debug(selectedCategory);
  // console.debug(displayItems);
  return (
    <div className="resource-roll">
      <div className="categories columns">
        {resourceCategories.map((category, index) => (
          <button
            className="button is-text"
            key={index}
            onClick={() => setCategoryIndex(index)}
            data-active={index === categoryIndex}
          >
            {category.title}
          </button>
        ))}
      </div>
      <div className="list">
        {visibleItems.map((item, index) => (
          <div className="item" key={index}>
            <p className="label-1">{item.label}</p>
            <a className="link" href={item.url}>
              {item.title}
            </a>
            {item.description ? (
              <p className="description">{item.description}</p>
            ) : null}
          </div>
        ))}
      </div>
      <LoadMoreButton
        enabled={isLoadMoreEnabled}
        onClick={() => setPage(page + 1)}
      />
    </div>
  );
};

const ResourceRoll = ({ data }) => {
  const { items, categories } = data;
  const { edges } = items;
  const resourceItems = edges
    .map((edge) => edge.node.frontmatter)
    .filter(isNotDraft);

  const resourceCategories = categories.edges.map((edge) => ({
    slug: getSlugToken(edge.node.fields.slug),
    title: edge.node.frontmatter.title,
  }));

  return (
    <ResourceRollTemplate
      resourceItems={resourceItems}
      resourceCategories={resourceCategories}
    />
  );
};

export default (props) => (
  <StaticQuery
    render={(data) => <ResourceRoll data={data} {...props} />}
    query={graphql`
      query ResourceRoll {
        items: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "resource-item" } } }
        ) {
          edges {
            node {
              frontmatter {
                draft
                date
                title
                label
                description
                url
                resource_items_category
              }
            }
          }
        }

        categories: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "resource-items-category" } } }
          sort: { fields: frontmatter___priority, order: ASC }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
  />
);
